// Brand colors
$primary: #1E1E1E;
$primary10: #1E1E1E1E;
$secondary: #F57F17;
$secondaryVariant: #FF9538;

// Background colors
$background: #FFFFFF;
$surface: #FFFFFF;
$backgroundControls: #FEF2E8;
$backgroundSelected: #FFFAF5;
$backgroundTableRowError: #FFD7D5;
$backgroundTableRowHiglight: #FEFFE2;
$backgroundAccent: #EFF0F3;

$blackTransparent: #5F6C72;
$disabled: #999999;

// Content for brand colors
$onPrimary: #F57F17;
$onSecondary: #FFFFFF;
$onSecondaryVariant: #FFFFFF;
$onDisabled: #FFFFFF;
$logoShadow: #ccc;

// Content
$contentLeading: #000000;
$contentSupporting: #37474F;
$contentAccessory: #999999;
$contentActionPrimary: #F57F17;
$contentHighlightPrimary: #F57F17;
$surfaceDivider: #EAE9E9;
$contentError: #F44336;
$contentSuccess: #4CAF50;
$border: #999999;

// Text Editor
$textEditorLink: #3AACE1;

// Alert colors
$backgroundSuccess: #4CAF50;
$backgroundError: #F44336;

// Custom Scroll
$scrollHovered: #A5A5A5;

// Calendar
$calendarDefaultDay: #FFFFFF;
$calendarDefaultDayAlt: #FFFAF5;

$transparentOrange: rgba(245, 127, 23, 0.5);
$calendarDayHover: #F5A65F;
$calendarToday: #F57F17;
$calendarTodayAlt: rgba(245, 127, 23, 0.8);

$calendarTimeOffDeclined: #D3D2D2;

$calendarPublicDayOff: #F74E1E;

$calendarDayOffRequested: #CDE9C5;
$calendarDayOffApproved: #82C785;

$calendarIllnessRequested: #C8EDF8;
$calendarIllnessApproved: #79C3DA;

$calendarUnpaidDayOffRequested: #D2A992;
$calendarUnpaidDayOffApproved: #AD7454;

$calendarLoggedDay: #009F06;
$calendarPartiallyLoggedDay: #D7D7D7;
$calendarInReviewDay: #F57F17;

$calendarRange: rgba(245, 127, 23, 0.2);


// Switcher
$switcherCircleOff: #E1E1E1;
$switcherCircleOn: #FED8B6;

// Password
$eyePasswordIcon: rgba(245, 127, 23, 0.2);

// Shadows
$shadowInfoCard: getRem(0) getRem(0) getRem(10) rgba(30, 30, 30, 0.1);
$shadowInfoDialog: getRem(0) getRem(0) getRem(20) rgba(30, 30, 30, 0.1);
$shadowHeader: getRem(0) getRem(3) getRem(8) rgba(30, 30, 30, 0.08);
$shadowSearch: getRem(0) getRem(0) getRem(8) rgba(30, 30, 30, 0.1);
$shadowScheduleReports: getRem(0) getRem(0) getRem(20) rgba(30, 30, 30, 0.15);
$shadowTable: getRem(0) getRem(0) getRem(10) rgba(30, 30, 30, 0.1);
$shadowTableRow: getRem(0) getRem(0) getRem(8) rgba(30, 30, 30, 0.1);
$shadowDropdown: getRem(0) getRem(6) getRem(20) rgba(30, 30, 30, 0.25),
    getRem(0) getRem(0) getRem(10) rgba(30, 30, 30, 0.1);
$shadowSelectedCalendarDate: getRem(0) getRem(0) getRem(7) rgba(30, 30, 30, 0.15),
    getRem(0) getRem(4) getRem(7) rgba(30, 30, 30, 0.15);
$shadowReportSummary: getRem(0) getRem(-3) getRem(8) rgba(30, 30, 30, 0.08);

// New Year Style
$newYearStyleHover: #E8F5FE;
$newYearBackground: #F8FCFF;

$newYearStyleSnowflake: getSnowflakeSvg(rgb(67, 174, 252));
$newYearStyleSnowflakeBackground: getSnowflakeSvg(rgb(193, 229, 255));

$newYearStyleSnowflakeMenuBlue: getSnowflakeSvg(rgb(67, 174, 252), 0.5);
$newYearStyleSnowflakeMenuLightBlue: getSnowflakeSvg(rgb(193, 229, 255), 0.5);
$newYearStyleSnowflakeMenuWhite: getSnowflakeSvg(rgb(255, 255, 255), 0.5);

$newYearStyleBorder: #314EB2;

// Easter Style
$easterBackgroundCard: #FFFFE1;
$easterBackground: #FFFFF8;
$easterEgg1: getEasterEgg1Svg();
$easterEgg2: getEasterEgg2Svg();
$easterEgg3: getEasterEgg3Svg();
$easterEgg4: getEasterEgg4Svg();
$easterEgg5: getEasterEgg5Svg();
$easterEgg6: getEasterEgg6Svg();

$easterEgg1Left: getEasterEgg1Svg(0.2, (-20));
$easterEgg1Right: getEasterEgg1Svg(0.2, 20);
$easterEgg3Left: getEasterEgg3Svg(0.2, (-30));
$easterEgg3Right: getEasterEgg3Svg(0.2, 30);
$easterEgg4Left: getEasterEgg4Svg(0.2, (-30));
$easterEgg4Right: getEasterEgg4Svg(0.2, 30);

$easterEgg1Menu: getEasterEgg1Svg(0.2);
$easterEgg2Menu: getEasterEgg2Svg(0.2);
$easterEgg3Menu: getEasterEgg3Svg(0.2);
$easterEgg4Menu: getEasterEgg4Svg(0.2);
$easterEgg5Menu: getEasterEgg5Svg(0.2);
$easterEgg6Menu: getEasterEgg6Svg(0.2);