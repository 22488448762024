@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.personal-purchases {
  display: block;
  padding-bottom: getRem(48);

  &-header {
    @extend .table-base-header-type-1;

    &-button {
      @extend .table-base-header-type-1-button;
    }

    &-search {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: getRem(24);
      height: fit-content;
      width: 100%;

      @include breakpoint(mobile_landscape) {
        margin-bottom: getRem(0);
      }
    }
  }

  &-content {
    display: flex;
    justify-content: left;

    &-table {
      @extend .table-base;
      margin-top: getRem(-20);

      th {
        padding-right: getRem(16);
      }

      td {
        padding-right: getRem(16);
        min-width: getRem(61);
        color: $contentSupporting;
      }

      &-first-column {
        min-width: getRem(225) !important;
        padding-left: getRem(32);
        color: $contentSupporting;
        position: relative;
        display: flex;
        align-items: center;

        @include breakpoint(mobile_landscape) {
          min-width: getRem(250) !important;
        }

        &-product {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: max-content !important;
          max-width: getRem(150) !important;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-word;
          max-height: getRem(50);
        }

        img {
          width: getRem(48);
          height: getRem(48);
          flex-shrink: 0;
          border-radius: getRem(4);
          margin-right: getRem(16);
          margin-top: getRem(4);
          margin-bottom: getRem(4);
          object-fit: cover;
        }
      }

      &-date {
        padding-left: getRem(32);
        min-width: getRem(96) !important
      }

      &-amount {
        padding-left: getRem(32);
        min-width: getRem(132) !important;
      }

      &-price {
        padding-left: getRem(32);
        min-width: getRem(132) !important;
        svg {
          width: getRem(18);
          height: getRem(18);
          margin-top: getRem(-3);
          margin-left: getRem(2);
        }
      }

      &-description {
        min-width: getRem(332) !important;
        padding-left: getRem(32);
        height: max-content;
        white-space: normal;
        word-break: break-word;
      }

      &-status {
        padding-left: getRem(32);
        min-width: getRem(102)
      }

      &-reason {
        padding-left: getRem(32);
        max-width: getRem(207) !important;

        span {
          width: getRem(150) !important;
          max-width: getRem(150) !important;
          overflow: hidden !important;
          text-overflow: ellipsis !important;
        }
      }

      &-last-column {
        min-width: getRem(112) !important;
      }

      &-green-span {
        color: $contentSuccess;
      }

      &-red-span {
        color: $contentError;
      }
    }
  }
}