@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';

.time-offs-popup {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: getRem(524) !important;
    max-height: getRem(520) !important;

    @include breakpoint(mobile) {
        width: getRem(293) !important;
    }

    @include breakpoint(tablet) {
        width: getRem(524) !important;
    }

    ::-webkit-scrollbar {
        @extend .custom-scroll-webkit-scrollbar;
    }

    ::-webkit-scrollbar-track {
        @extend .custom-scroll-webkit-scrollbar-track;
    }

    ::-webkit-scrollbar-thumb {
        @extend .custom-scroll-webkit-scrollbar-thumb;
    }

    &-loading {
        display: flex;
        width: getRem(524);
        justify-content: center;
        align-items: center;
    }

    &-title {
        color: $contentLeading;
        margin-bottom: getRem(4);
    }

    &-subtitle {
        color: $contentAccessory;
        margin-bottom: getRem(32);
        text-align: center;
    }

    &-left {
        display: flex;
        justify-content: space-between;
        margin-bottom: getRem(12);
        align-items: flex-start;

        @include breakpoint(mobile) {
            width: getRem(285);
        }

        @include breakpoint(tablet) {
            width: getRem(524);
            margin-bottom: getRem(22);
        }

        .custom-select {
            margin-top: getRem(-5) !important;
            flex-shrink: 0;
            overflow: visible;
            width: max-content;
            font-size: getRem(14);

            @include breakpoint(tablet) {
                font-size: getRem(18);
            }
        }

        &-info {
            color: $contentHighlightPrimary;

            &-inactive {
                color: $contentAccessory;
            }

            &-container {
                display: flex;
                flex-direction: column;
                margin-bottom: getRem(12);

                span {
                    font-size: getRem(12) !important;
                }
            }
        }
    }

    &-table {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: getRem(32);

        &-head {
            margin-bottom: getRem(-40) !important;
        }

        &-row {
            color: $contentLeading;
            position: relative !important;
            display: flex;
            justify-content: space-between;
            width: getRem(429);

            @include breakpoint(mobile) {
                width: getRem(318);
            }

            @include breakpoint(tablet) {
                width: getRem(429);
            }

            &:last-child {
                margin-bottom: getRem(24);
            }

            &-type {
                width: getRem(145) !important;

                @include breakpoint(mobile) {
                    margin-left: getRem(12);
                    width: getRem(205) !important;
                }

                @include breakpoint(desktop) {
                    margin-left: 0;
                    width: getRem(145) !important;
                }

                .dot {
                    height: getRem(10);
                    width: getRem(10);
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: getRem(8);
                }
            }

            &-period {
                width: getRem(150) !important;

                @include breakpoint(mobile) {
                    width: getRem(200) !important;
                    margin-left: getRem(20);
                }

                @include breakpoint(tablet) {
                    width: getRem(150) !important;
                }
            }

            &-days {
                width: getRem(34) !important;
                text-align: right;
                margin-right: getRem(30);

                @include breakpoint(tablet) {
                    margin-right: getRem(0);
                }

                &-mobile {
                    color: $contentAccessory;
                }
            }
        }

        &-divider {
            margin-top: getRem(12);
            margin-bottom: getRem(12);
            color: $contentSupporting;
            opacity: 0.2;
            width: getRem(524);

            @include breakpoint(mobile) {
                width: getRem(312);
            }

            @include breakpoint(tablet) {
                width: getRem(524);
            }

            &.first {
                margin-top: getRem(0);
            }
        }
    }

    &-in-review {
        background-color: $secondary;
        position: absolute;
        top: getRem(-12);
        right: getRem(-95);
        margin-bottom: 0;
        border-radius: getRem(3);
        width: max-content;
        color: white;
        padding: getRem(2) getRem(9);
        display: block;
    }
}

.working-year-react-select {

    &__control {
        box-shadow: none !important;
        cursor: pointer !important;
        border-radius: getRem(80) !important;
        border-color: $background !important;
    }

    &__indicator {
        color: $secondary !important;
        transform: scale(1.5) !important;
        padding: 0 !important;
        height: getRem(15);
        width: getRem(15);
        svg {
            height: auto;
        }
    }

    &__indicator:hover {
        color: $secondaryVariant !important;
    }

    &__menu {
        right: getRem(0);
        min-width: max-content;
        border-radius: getRem(8) !important;
        box-shadow: $shadowInfoCard !important;
        margin-top: 0 !important;
    }

    &__menu-list {
        max-height: getRem(150) !important;
        overflow: auto !important;
        background-color: $background !important;
        border-radius: 0 0 0 getRem(8) !important;

        &::-webkit-scrollbar-track {
            background: $background;
        }
    }

    &__option {
        @extend .t-s4;
        color: $contentSupporting !important;
        font-weight: 400 !important;
        background-color: $background !important;
        width: getRem(65) !important;

        &--is-selected {
            @extend .t-s3;
            color: $contentHighlightPrimary !important;
        }
    }

    &__option:hover {
        background-color: $backgroundControls !important;
        cursor: pointer;
    }
}

.time-offs-balance {
    padding-bottom: getRem(48);

    &-select-year {
        margin-top: getRem(24);
        margin-right: getRem(-2);

        @include breakpoint(tablet) {
            margin-right: 0;
        }
    }

    &-header {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: right;
        padding-top: getRem(14);
        padding-bottom: getRem(24);
        padding-left: getRem(16);
        padding-right: getRem(16);

        @include breakpoint(mobile_landscape) {
            flex-direction: row;
            justify-content: space-between;
            padding-left: getRem(25);
            padding-right: getRem(50);
        }

        &-select-year {
            margin-top: getRem(10);
            margin-bottom: getRem(-24);

            @include breakpoint(mobile_landscape) {
                margin-top: 0;
                margin-bottom: 0;
            }
        }

        &-status-filter {
            margin-left: getRem(16);
            margin-bottom: getRem(-24);

            @include breakpoint(mobile_landscape) {
                margin-left: getRem(24);
                margin-bottom: 0;
            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;

            &-row {
                cursor: pointer;
            }

            td {
                min-width: getRem(110) !important;
                padding-right: getRem(10) !important;

                &:last-child {
                    text-align: left;
                }
            }

            th {
                padding-right: getRem(10) !important;
            }

            .transferred {
                color: $contentAccessory;
            }

            .warning {
                color: $contentError;
            }
        }
    }
}
