@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.products {
  display: block;
  padding-bottom: getRem(48);

  &-header {
    @extend .table-base-header-type-1;

    &-button {
      @extend .table-base-header-type-1-button;
    }

    &-search {
      display: block;
      height: fit-content;
      width: 100%;
      margin-bottom: getRem(20);

      @include breakpoint(mobile_landscape) {
        margin-bottom: getRem(0);
      }

      @include breakpoint(tablet_landscape) {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
      }
    }

    &-filters {
      margin-top: getRem(24);
      display: flex;
      gap: getRem(16);
      flex-direction: column;
      position: relative;

      @include breakpoint(mobile_landscape) {
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        gap: getRem(56);
        width: 100%;
      }

      &-category {
        display: flex;
        gap: getRem(16);
        align-items: center;
        margin-left: getRem(3);
        margin-top: getRem(-21);

        .multiple-select {
          margin-top: getRem(3);
        }

        @include breakpoint(mobile_landscape) {
          margin-left: 0;
          margin-top: getRem(6);
        }

        &-title {
          color: $contentAccessory;
        }
      }

      &-sort {
        margin-top: getRem(-10);

        @include breakpoint(mobile_landscape) {
          margin-top: getRem(6);
          position: absolute;
          right: getRem(2);
        }
      }
    }
  }

  &-content {
    display: flex;
    justify-content: center;

    &-table {
      @extend .table-base;
      margin-top: getRem(-20);

      tr {
        cursor: pointer;
      }

      td {
        min-width: getRem(180) !important;
        color: $contentSupporting;
      }

      &-price {
        svg {
          width: getRem(18);
          height: getRem(18);
          margin-top: getRem(-3);
          margin-left: getRem(2);
        }
      }

      &-first-column {
        padding-left: getRem(32);
        padding-right: getRem(16);
        color: $contentSupporting;
        width: getRem(400) !important;
        display: flex;
        align-items: center;

        @include breakpoint(mobile_landscape) {
          padding-right: 0;
          width: auto;
          max-width: getRem(400) !important;
        }

        span {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          max-width: getRem(336);
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-word;
          max-height: getRem(50);
        }

        img {
          width: getRem(48);
          height: getRem(48);
          flex-shrink: 0;
          margin-top: getRem(3);
          border-radius: getRem(4);
          margin-right: getRem(16);
          object-fit: cover;
          display: inline;
        }
      }

      &-last-column {
        z-index: 2;
        width: getRem(180);
        position: relative;

        &-ext {
          width: getRem(140);
        }

        &-inventory-popup {
          &-content {
            margin-bottom: getRem(40);

            .head-option {
              margin-left: getRem(8);
              margin-bottom: getRem(16);
              max-width: getRem(190);
              display: block;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
            }

            .head-status {
              margin-bottom: getRem(16);
              display: block;
              max-width: getRem(170);
              text-transform: uppercase;
            }

            &-table {
              min-width: getRem(350);
              padding: getRem(0);
              margin-bottom: getRem(20);

              &-status {
                width: getRem(170);
              }

              .toolkit-text-input-wrapper > input:disabled {
                background-color: $background;
              }

              &-name {
                margin-left: getRem(8);
                margin-bottom: getRem(16);
                width: getRem(300);
              }

              &-option {
                margin-left: getRem(8);
                margin-bottom: getRem(16);
                width: getRem(190);
              }

              .not-exist {
                .toolkit-text-input-wrapper > input:disabled {
                  color: $disabled;
                  border-color: $disabled;
                }
              }

              &-row:last-child {
                .toolkit-dd-list-select__menu-list {
                  max-height: getRem(80);
                  overflow: auto !important;
                }
              }
            }
          }

          &-wrapper {
            .custom-popup-card {
              max-width: max-content;
              padding-bottom: getRem(0);

              &-title {
                margin-bottom: getRem(32);
              }
            }

            .popup-bottom-button {
              display: flex;
              justify-content: center;

              &-container {
                padding: getRem(0);
              }
            }
          }
        }
      }

      &-unpublished-label {
        display: flex;
        padding: getRem(3) getRem(7);
        justify-content: center;
        align-items: center;
        position: absolute;
        right: getRem(16);
        top: getRem(-5);
        border-radius: getRem(3);
        background: $disabled;
        color: $onSecondary;
        text-transform: lowercase;
      }

      &-green-span {
        color: $contentSuccess;
      }

      &-red-span {
        color: $contentError;
      }
    }
  }
}