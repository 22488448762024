@import '../../styles/index.scss';

.ns-typography {
  margin-bottom: 0;

  &-header {
    font-family: Gilroy, serif;
    font-size: getRem(30);
    font-weight: 600;
    text-align: left;
    color: $contentLeading;
  }

  &-leading {
    font-family: Gilroy, serif;
    font-size: getRem(18);
    font-weight: 600;
    text-align: left;
    color: $contentLeading;

    &2 {
      @extend .ns-typography-leading;
      font-weight: 400;
      font-size: getRem(14);
    }
  }

  &-supporting {
    &1 {
      @extend .ns-typography-supporting;
      font-size: getRem(18);
    }

    &2 {
      @extend .ns-typography-supporting;
      font-size: getRem(14);
    }

    &3 {
      @extend .ns-typography-supporting;
      font-size: getRem(11);
    }

    font-size: getRem(14);
    font-family: Gilroy, serif;
    font-weight: 400;
    text-align: left;
    color: $blackTransparent;
  }
}

.ns-description-list {
  p {
    margin-bottom: 0 !important;
  }

  &-bullet {
    padding-left: getRem(8);
    padding-right: getRem(8);
  }
}

.text-snippet {
  border-radius: getRem(5);
  border: 1px solid $backgroundAccent;
  height: 100%;
  overflow: hidden;

  &-header {
    font-size: getRem(14);
    font-family: "Gilroy", serif;
    font-weight: 400;
    line-height: getRem(17);

    background: $backgroundAccent;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    padding: getRem(4) getRem(6);
  }

  &-content {
    height: 100%;
    overflow: scroll;
    color: $primary;
    font-family: "Source Code Pro", serif;
    font-size: getRem(14);
    font-weight: 400;
    line-height: getRem(17);
    padding: getRem(16);
  }
}
