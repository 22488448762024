@import '../../styles/index.scss';


.custom-popup {

    &-container {
        box-shadow: $shadowInfoDialog;
    }

    &-agree {
        padding-top: getRem(48) !important;
        padding-bottom: getRem(32) !important;

        .popup-bottom-button-container {
            padding-top: getRem(20);
        }
    }

    &-close-icon {
        position: absolute;
        top: getRem(15);
        right: getRem(20);
        cursor: pointer;
    }

    &-card {
        position: relative;
        display: block;
        background: $background;
        box-shadow: $shadowInfoCard;
        border-radius: getRem(8);
        min-width: getRem(300);
        max-width: getRem(432);
        width: 90vw;
        padding-top: getRem(20);
        padding-bottom: getRem(20);
        letter-spacing: getRem(1);
        outline: none;
        max-height: 80vh;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }
          
        &::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }
          
        &::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }
        
        @include breakpoint(mobile_landscape) {
            padding-top: getRem(35);
            padding-bottom: getRem(35);
            position: static;
            max-height: 90vh;
        }

        &-wrapper {
            display: block;
            height: auto;
        }

        &-unscrollable-wrapper {
            @extend .custom-popup-card-wrapper;
            overflow: initial !important;
        }

        .title-base {
            display: block;
            width: 100%;
            text-align: center;
            padding-left: getRem(12);
            padding-right: getRem(12);
            
            @include breakpoint(mobile_landscape) {
                padding-left: getRem(24);
                padding-right: getRem(24);
            }
        }

        &-title {
            @extend .title-base;
            margin-bottom: getRem(27);
            color: $contentLeading;
        }
        
        &-subtitle {
            @extend .title-base;
            
            margin-bottom: getRem(12);
            color: $contentSupporting;
        }

        &-content {
            padding-left: getRem(27);
            padding-right: getRem(27);

            @include breakpoint(mobile_landscape) {
                padding-left: getRem(57);
                padding-right: getRem(57);
            }

            @include breakpoint(tablet) {
                padding-left: getRem(48);
                padding-right: getRem(48);
            }
        }
    }
}

.popup-active-block-background {
    pointer-events: none;
    overflow: hidden;
}

.custom-popup-container::-webkit-scrollbar {
    @extend .custom-scroll-webkit-scrollbar;
}
  
.custom-popup-container::-webkit-scrollbar-track {
    @extend .custom-scroll-webkit-scrollbar-track;
}
  
.custom-popup-container::-webkit-scrollbar-thumb {
    @extend .custom-scroll-webkit-scrollbar-thumb;
}
