@import '../../styles/index.scss';

.google-integration {
  margin: 2rem 2.5rem 1rem 2.5rem !important;
  padding: 1rem 2.5rem 1rem 2.5rem !important;

  button {
    width: 100%;
    @include breakpoint(mobile_landscape) {
      width: auto;
    }
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    row-gap: 1rem;

    &-title-container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 2rem;

      &-icon {
        width: 120px;
        height: 120px;
      }
    }
  }


  &-body {

    margin-left: 0;

    @include breakpoint(tablet) {
      margin-left: 152px;
    }

    &-description {
    }

    &-authorization {
      display: flex;
      flex-direction: row;
      column-gap: 1.5rem;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }


    &-ooo-calendar {
      display: flex;
      flex-direction: row;
      column-gap: 1.5rem;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      &-popup {
        // A small hack to make the dropdown selection options visible outside the popup. We should replace DD with MUI Select
        // that creates a separate node on the root level to prevent such issues
        .custom-popup-card {
          overflow: visible !important;
        }
      }

      &-dd {
        width: 100%;
      }
    }

  }


}