@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.roles {

    &-header {
        @extend .table-base-header-type-1;
        padding-top: 0;

        @include breakpoint(tablet_landscape) {
            justify-content: flex-end;
            padding-top: getRem(24);
        }
    
        &-button {
            @extend .table-base-header-type-1-button;
        }  
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;
            min-width: getRem(1300);

            td:first-child, th:first-child {
                padding-left: getRem(31);
                width: getRem(250);
            }

            td:nth-child(2), td:nth-child(3) {
                width: getRem(400);
                padding-right: getRem(20);

                span {
                    display: inline-block;
                    width: fit-content;
                    max-width: getRem(280);
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }
            }

            td:last-child {
                width: getRem(50);
            }
        }
    }

    &-popup {
        z-index: -100;

        .custom-popup-card-content {
            padding-left: getRem(32);
            padding-right: getRem(32);
        }

        &-label {
            padding-left: getRem(10);
            color: $contentSupporting;
        }

        &-message {
            color: $contentSupporting;
            margin-bottom: getRem(15);
        }

        &-box {
            display: block;
            height: getRem(299);
            border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
            border-radius: getRem(8);
            overflow: hidden;

            &-ext {
                @extend .roles-popup-box;
                height: getRem(232);

                &-progress {
                    height: 100%;
                    display: flex;
                    align-items: center;
                }
            }

            &-search {
                display: block;
                height: getRem(72);
                padding: getRem(16) getRem(15) getRem(16) getRem(15);

                form {
                    width: 100%;
                    height: getRem(40);
                }
            }
        }

        &-table {
            display: block;
            padding: 0 getRem(15) 0 getRem(15);
            overflow-y: overlay;
            height: calc(100% - getRem(72));

            &-ext {
                @extend .roles-popup-table;
                height: 100%;
                padding: 0;
                overflow-y: auto;
            }

            &-list {
                @extend .roles-popup-table;
                padding: 0;
                overflow-y: auto;
            }

            &-row {
                position: relative;
                display: block;
                width: 100%;
                padding: getRem(8.5) 0 getRem(6.5);

                &-ext {
                    @extend .roles-popup-table-row;
                    overflow-x: clip;
                    text-overflow: ellipsis;

                    &:hover {
                        background-color: $backgroundControls;
                        color: $contentLeading;
                    }

                    span {
                        padding: 0 getRem(15) 0 getRem(16);
                    }

                    &-img-wrap {
                        display: inline-block;
                        padding-left: getRem(16);

                        img {
                            width: getRem(24);
                            height: getRem(24);
                            border-radius: 100%;
                            margin-right: getRem(-8);
                        }
                    }
                }

                span {
                    color: $contentSupporting;
                }

                > img {
                    position: absolute;
                    width: getRem(14);
                    height: getRem(14);
                    right: getRem(15);
                    top: getRem(13);
                    cursor: pointer;
                }
            }

            // apply custom styles for scroll
            &::-webkit-scrollbar {
                @extend .custom-scroll-webkit-scrollbar;
            }              
            &::-webkit-scrollbar-track {
                @extend .custom-scroll-webkit-scrollbar-track;
            }
            &::-webkit-scrollbar-thumb {
                @extend .custom-scroll-webkit-scrollbar-thumb;
            }
        }
    }
}
