@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.equipment {
    display: block;
    padding-bottom: getRem(48);

    &-header {
        padding-left: getRem(16);
        padding-right: getRem(16);

        @include breakpoint(mobile_landscape) {
            padding-left: getRem(25);
            padding-right: getRem(25);
        }

        &-search {
            display: block;
            height: fit-content;
            width: 100%;
            padding-top: getRem(24);
            margin-bottom: getRem(20);

            @include breakpoint(mobile_landscape) {
                margin-bottom: getRem(30);
            }

            @include breakpoint(tablet_landscape) {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: center;
            }

            &-button {
                @extend .table-base-header-type-1-button;
            }
        }

        &-categories {
            display: flex;
            flex-direction: column;
            
            @include breakpoint(mobile_landscape) {
                flex-direction: row;
                justify-content: space-between;
                padding-bottom: getRem(30);
            }
            &-box {
                display: flex;
                gap: getRem(30);

                &:first-child {
                    justify-content: right;
                }

                &-content {
                    display: flex;
                    height: getRem(60);
                    gap: getRem(30);
                    overflow-x: scroll;

                    @include breakpoint(mobile_landscape) {
                        height: auto;
                        margin-left: getRem(60);
                    }

                    button:first-child {
                        white-space: nowrap;
                        width: getRem(89);

                        @include breakpoint(mobile_landscape){
                            margin-left: getRem(60);
                        }
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-progress {
            display: block;
            width: 100%;
        }

        &-table {
            @extend .table-base;

            &-link {
                @extend .custom-scrollable-table-link;
                display: inline-block;
                text-align: right;
                width: getRem(30);
                margin-left: getRem(30);
                margin-right: 0;
            }

            td:last-child {
                width: getRem(191);
            }

            td:first-child {
                @extend .custom-scrollable-table-employee-name;
                width: getRem(370) !important;
                padding-left: getRem(31);

                .cell-limited-text {
                    width: getRem(340);
                }
            }

            td:nth-child(2) {
                .cell-limited-text {
                    width: getRem(170);
                }
            }

            td:nth-child(3) {
                .cell-limited-text {
                    width: getRem(170);
                }
            }

            td:nth-child(2), td:nth-child(3) {
                width: getRem(170);
            }

            td:nth-child(4) {
                width: getRem(220);
            }

            &-link:first-child {
                width: getRem(55);
                margin-left: getRem(10);
            }

            &-link:last-child {
                width: getRem(40);
                margin-left: getRem(25);
                margin-right: getRem(33);

                @include breakpoint(desktop) {
                    margin-right: 0;
                }
            }
        }

        &-arrow {
            width: getRem(10.66);
            height: getRem(5.9);
            position: relative;
            margin-left: getRem(3);
            bottom: getRem(1);
            filter: grayscale(100%);
            cursor: pointer;
            user-select: none;
            color: $secondary;
        }
    }
}

.equipment-content-arrow.active-arrow {
    filter: none;
}

.equipment-content-arrow.rotate-arrow {
    transform: rotate(180deg);
}

.assign-equipment-popup {
    position: static;
    overflow: visible !important;

    .toolkit-dd-list-select__menu {
        max-height: getRem(150);
        
        * {
            max-height: getRem(150);
        }
    }
}

.create-update-equipment-popup {
    position: static;
    overflow: visible !important;

    .toolkit-dd-list-select__menu {
        max-height: getRem(130);

        * {
            max-height: getRem(130);
        }
    }
}

.custom-select {
    background: none !important;
    border: none !important;
}
