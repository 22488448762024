@import '../../styles/index.scss';

.newcoin {
  &.active path {
    stroke: $secondary;
  }

  &.received path {
    stroke: $contentSuccess;
  }

  &.withdrawn path {
    stroke: $contentError;
  }

  &.inactive path {
    stroke: $contentSupporting;
  }

  &.empty path {
    stroke: $contentAccessory;
  }

  &.neutral path {
    stroke: $background;
  }
}