@import '../../../styles/index.scss';


.audit-logs-accordion {
  display: flex;
  padding: getRem(24);
  height: getRem(300);


  &-data {
    padding-left: getRem(32);
    width: 50%;
    height: fit-content;

    &-value {
      padding-left: 2rem;
      display: flex;
      align-items: center;
    }

    table {
      border-collapse: separate;
      border-spacing: 0.5rem;
    }
  }

  &-event-content {
    width: 50%;
    height: 100%;
    overflow: hidden;
  }
}