@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';

.calendars {
  display: block;
  padding-top: getRem(28);
  -webkit-overflow-scrolling: touch;

  &-content {
    display: flex;
    justify-content: flex-start;
    align-content: flex-start;
    flex-wrap: wrap;
    gap: getRem(16);
    padding: getRem(20) getRem(10) getRem(48) getRem(32);

    .selected {
      background: $backgroundSelected !important;
      border: getRem(0.5) solid $onPrimary !important;
    }

    &-card {
      min-width: getRem(200);
      height: getRem(80);
      padding: getRem(32) getRem(24);
      border-radius: getRem(8);
      background: $background;
      box-shadow: 0 0 getRem(10) 0 $primary10;
      flex: 1 1 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      @include breakpoint(tablet_landscape) {
        max-width: 32%;
        flex: 1 1 32%;
      }

      &:hover {
        background: $backgroundSelected;
      }

      &-name {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: getRem(16);

        span {
          max-width: getRem(150);
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          @include breakpoint(mobile_landscape) {
            max-width: getRem(450);
          }

          @include breakpoint(tablet_landscape) {
            max-width: getRem(150);
          }

          @include breakpoint(desktop) {
            max-width: getRem(250);
          }
        }

        svg {
          width: getRem(6);
          height: getRem(6);
          color: $secondary;
          border-radius: 50%;
        }
      }

      &-days {
        justify-self: flex-end;
        color: $contentAccessory;
      }
    }

    .add-calendar {
      min-width: getRem(200);
      flex: 1 1 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      @include breakpoint(tablet_landscape) {
        max-width: 32%;
        flex: 1 1 32%;
      }

      svg {
        width: getRem(24);
        height: getRem(24);
        font-size: getRem(30);
      }
    }
  }

  &-popup {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: getRem(450);
 
    .toolkit-dd-list-wrapper {
      width: 90%;
    }

    &-title {
      margin-bottom: getRem(32);
      display: flex;
      justify-content: center;
      text-align: center;
    }

    &-subtitle {
      margin-bottom: getRem(16);
      display: flex;
      justify-content: center;
      text-align: center;
    }

    &-bottom {
      display: flex;
      justify-content: center;
      gap: getRem(16);
      margin-top: getRem(24);
      width: 100%;
    }
  }
}

.events-side-panel {
  z-index: 98;
  position: fixed;
  overflow: visible;
  top: getRem(69);
  right: getRem(0);
  height: calc(100% - getRem(69));
  width: getRem(404);
  display: inline-flex;
  padding: getRem(0);
  flex-direction: column;
  align-items: center;
  background: $background;

  &-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 90;
  }

  &-header {
    position: absolute;
    top: 0;
    display: flex;
    width: getRem(404);
    background: $background;
    padding: getRem(24) getRem(24) getRem(24) getRem(24);
    flex-direction: column;
    align-items: flex-start;
    gap: getRem(24);
    z-index: 99;
    box-shadow: inset getRem(0) getRem(7) getRem(8) rgba(30, 30, 30, 0.08);

    &-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      align-self: stretch;
      width: 100%;

      &-input {
        border: none;
        background: none;
        font-size: getRem(32);
        font-weight: bold;
        color: $primary;
        outline: none;
        padding: 0;
        white-space: nowrap;
        max-width: getRem(250) !important;
        margin-right: getRem(8);
      }

      &-default-label {
        color: $contentAccessory;
      }

      svg {
        color: $secondary;
        cursor: pointer;
      }
    }
  }

  &-content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 98;

    &-empty {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &-buttons {
        margin-top: getRem(16);
        display: flex;
        align-items: flex-start;
        gap: getRem(16);
        align-self: stretch;

        button {
          width: getRem(174);

          &:only-child {
            width: 100%;
          }
        }
      }
    }

    &-events {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100%;
      padding: getRem(10) getRem(24) getRem(137) getRem(24);
      margin-top: getRem(130);
      overflow-y: scroll;
      overflow-x: hidden;
      border-top: getRem(0.5) solid $disabled;

      &::-webkit-scrollbar {
        display: none;
      }

      &-add-event {
        margin-top: getRem(-7);
        padding-right: getRem(6);
        align-self: flex-end;
        cursor: pointer;
        color: $blackTransparent;
      }
    }

    &-event {
      position: relative;
      display: flex;
      align-items: flex-start;
      gap: getRem(16);
      width: getRem(356);
      height: getRem(40);
      align-self: stretch;
      margin-bottom: getRem(16);

      .single-date-input {
        width: getRem(115) !important;
        min-width: getRem(115) !important;
      }

      .toolkit-text-input {
        width: getRem(225) !important;
      }

      &-date-picker {
        @extend .t-s4;
        color: $contentSupporting;
        text-align: left;
        height: getRem(40);
        width: getRem(115);
        background: $surface;
        outline: none;
        border: getRem(0.5) solid $contentAccessory;
        padding-left: getRem(15);
        display: block;
        padding-right: getRem(6);
        border-radius: getRem(8);

        &::-webkit-calendar-picker-indicator {
          padding-left: getRem(5);
          font-size: getRem(20);
          color: $secondary;
          border-left: getRem(0.5) solid $contentAccessory;
        }

        &:disabled {
          color: $disabled;
        }

        &:disabled + svg {
          cursor: default !important;
          stroke: $disabled;
        }
      }

      .rmdp-container > div:nth-child(3) {
        position: fixed !important;
      }

      .toolkit-text-input-wrapper > input:disabled {
        background-color: $background;
        color: $disabled;
        border: getRem(0.5) solid $disabled;
      }

      .validation-error {
        font-size: getRem(12);
        display: flex;
        padding-left: getRem(5);
      }
    }

    &-remove {
      position: absolute;
      top: getRem(-8);
      right: -1.5%;
      cursor: pointer;
      width: getRem(16) !important;
      height: getRem(16) !important;
      color: $disabled;
      background: $background;

      &:hover {
        color: $secondary;
      }
    }

    .no-scroll {
      overflow: hidden;
    }
  }

  &-footer {
    display: flex;
    padding: getRem(24);
    flex-direction: column;
    align-items: flex-start;
    gap: getRem(16);
    position: absolute;
    z-index: 99;
    bottom: 0;
    width: getRem(404);
    border-top: getRem(0.5) solid $disabled;
    background: $background;

    button {
      width: 100%;
    }
  }
}

.delete-assigned-calendar-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: getRem(450);
  position: static;
  overflow: visible !important;

  .toolkit-dd-list-select__menu {
      max-height: getRem(130);

      * {
          max-height: getRem(130);
      }
  }
  &-subtitle {
    margin-bottom: getRem(16);
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &-bottom {
    display: flex;
    justify-content: center;
    gap: getRem(16);
    margin-top: getRem(24);
    width: 100%;
  }
}