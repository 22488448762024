@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.time-off-requests {
    display: block;
    padding-bottom: getRem(48);

    &-header {
        @extend .table-base-header-type-1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;

        padding-bottom: getRem(40);
        margin-bottom: 0;

        @include breakpoint(mobile_landscape) {
            margin-bottom: 0;
        }

        &-user {
            @extend .time-off-requests-header;
            padding-bottom: getRem(10);
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;

            &-first-column {
                padding-left: getRem(70);
            }

            &-multi-line {
                white-space: pre-line;
                word-break: break-all;
                width: getRem(350);
                padding-top: getRem(10);
                padding-bottom: getRem(10);
                padding-right: getRem(50);
            }

            td:last-child {
                width: getRem(170);
            }
        }
    }

    &-popup-content {
        &-label {
            margin-top: getRem(15);
            padding-left: getRem(10);
            color: $contentSupporting;
        }
    }
}

.show-panel-approve {
    color: $secondary;
    text-decoration: underline;
    cursor: pointer;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(getRem(2));
}

.approved-time-off-requests-span {
    color: $contentSuccess;
}

.declined-time-off-requests-span {
    color: $contentError;
}

.dot-types {
    height: getRem(6);
    width: getRem(6);
    border-radius: 50%;
    display: inline-block;
    margin-right: getRem(6);
    margin-bottom: getRem(2);
}

.time-off-status {
    &-filter {
        display: inline-flex;
        justify-content: flex-end;
        gap: getRem(16);
        position: absolute;
        right: getRem(20);
        top: getRem(75);

        @include breakpoint(tablet_landscape) {
            top: getRem(32);
            right: getRem(27);
        }

        &-span {
            display: flex;
            align-items: center;
            color: $contentAccessory;
            padding: getRem(4);
        }

    }

    &-button {
        border: none;
        padding: getRem(4);
        cursor: pointer;
        outline: inherit;
        background: none;
        color: $contentSupporting;

        &-selected {
            color: $contentSupporting;
            text-decoration: underline;
            text-decoration-color: $secondary;
        }
    }
}