@import '../../styles/index.scss';


.dashboard {
    height: fit-content;
    padding-top: getRem(32);
    padding-bottom: getRem(60);
    position: relative;

    @include breakpoint(tablet) {
        padding-top: getRem(41);
    }

    &-admin {
        @include breakpoint(tablet) {
            padding-left: getRem(35);
        }
    }

    &-box {
        display: flex;
        flex-wrap: wrap;
        justify-content: left;
        width: auto;
        height: fit-content;
        gap: getRem(24);
        padding-left: getRem(33);

        @include breakpoint(mobile_landscape) {
            padding-left: getRem(40);
            gap: getRem(32);
        }

        @include breakpoint(tablet) {
            padding-left: getRem(50);
        }

        @include breakpoint(tablet_landscape) {
            padding-left: getRem(60);
        }

        @include breakpoint(desktop) {
            gap: getRem(32);
            padding-left: getRem(41);
        }

        &-card {
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            border: 1px solid transparent;
            width: getRem(311);
            height: getRem(86);

            @include breakpoint(mobile_landscape) {
                width: getRem(280);
            }

            @include breakpoint(tablet_landscape) {
                width: getRem(364);
            }

            @include breakpoint(desktop) {
                width: getRem(298);
                height: getRem(138);
            }

            &-content {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                width: getRem(311);
                height: getRem(86);

                @include breakpoint(mobile_landscape) {
                    width: getRem(280);
                }

                @include breakpoint(tablet_landscape) {
                    width: getRem(364);
                }

                @include breakpoint(desktop) {
                    width: getRem(298);
                    height: getRem(138);
                }

                &-title {
                    color: $contentLeading;
                    text-align: center;

                    &:hover {
                        border: none !important;
                    }
                }
    
                &-notification {
                    position: absolute;
                    top: getRem(-15);
                    right: getRem(-15);
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    width: getRem(34);
                    height: getRem(34);
                    background-color: $secondary !important;
                    color: $onSecondary !important;
                    border-radius: 100%;
                    padding-top: getRem(2);

                    &-text {
                        background-color: $secondary !important;
                    }
                }
            }
        }

        &-card:hover {
            background: $backgroundControls;
        }
    }
}
