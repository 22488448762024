@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.shop {
  display: block;
  padding-bottom: getRem(48);
  -webkit-overflow-scrolling: touch;

  &-header {
    @extend .table-base-header-type-1;

    &-filters {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      overflow: visible;

      @include breakpoint(mobile_landscape) {
        align-items: center;
        overflow: visible;
        flex-direction: row;
        justify-content: space-between;
      }

      @include breakpoint(tablet_landscape) {
        padding-right: getRem(24);
      }
    }

    &-banner {
      display: flex;
      width: getRem(343);
      flex-direction: column;
      gap: getRem(40);
      align-items: center;
      margin-top: getRem(10);

      @include breakpoint(mobile_landscape) {
        margin: getRem(60) 0 getRem(60) 0;
        width: 100%;
      }

      @include breakpoint(tablet_landscape) {
        flex-direction: row;
        justify-content: space-between;
        margin: getRem(60) 0 getRem(80) 0;
        width: getRem(1290);
      }

      &-left {
        width: getRem(343);
        display: flex;
        flex-direction: column;

        @include breakpoint(mobile_landscape) {
          width: getRem(506);
        }

        @include breakpoint(tablet_landscape) {
          width: getRem(400);
        }

        &-title {
          color: $primary;
          font-size: getRem(40);
          font-style: normal;
          font-weight: 400;
          line-height: getRem(44);
          margin-bottom: getRem(16);
        }

        &-claim-coupon {
          margin-top: getRem(30);

          &-success-wrapper {
            display: block;
            height: 100vh;
            width: 100vw;
            background-color: $primary;
            color: $onSecondary;
            box-shadow: $shadowInfoDialog;

            @include breakpoint(mobile_landscape) {
              width: getRem(1092);
              max-width: 100vw;
              border-radius: getRem(8);
            }

            @include breakpoint(tablet_landscape) {
              max-height: 80vh;
              max-width: 80vw;
            }

            @include breakpoint(desktop) {
              height: max-content;
              max-height: 95vh;
            }
          }

          &-error-message {
            text-align: center;
          }
        }
      }

      &-right {
        display: flex;
        width: getRem(343);
        flex-direction: column;
        align-items: flex-start;
        gap: getRem(16);
        flex-shrink: 0;

        @include breakpoint(mobile_landscape) {
          width: getRem(506);
          height: getRem(203);
        }

        &-info {
          display: flex;
          flex-direction: column;
          gap: getRem(16);

          @include breakpoint(mobile_landscape) {
            flex-direction: row;
          }
        }

        .balance-card {
          display: flex;
          padding: getRem(24) getRem(16) getRem(24) getRem(24);
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;
          flex: 1 0 0;
          align-self: stretch;
          box-shadow: $shadowInfoCard;
          border-radius: getRem(8);
          background: $background;
          position: relative;
          width: getRem(343);
          height: getRem(106);
          cursor: pointer;

          @include breakpoint(mobile_landscape) {
              width: getRem(245);
              height: getRem(131);
              padding: getRem(24) getRem(16) getRem(32) getRem(24);
          }

          &-title {
            color: $contentLeading;
          }

          &-newcoins {
            color: $secondary;

            svg {
              width: getRem(22) !important;
              height: getRem(22) !important;
              margin-top: getRem(-3);
            }
          }

          &-dots {
            position: absolute;
            top: getRem(16);
            right: getRem(16);
            color: $switcherCircleOn;
          }

          &:hover {
            background: $backgroundControls;
          }
        }

        .spend-card {
          @extend .balance-card;
          cursor: default;

          &:hover {
            background: $background;
          }

            &-title {
                color: $contentLeading;
            }

            &-newcoins {
              color: $contentError;

              svg {
                width: getRem(22) !important;
                height: getRem(22) !important;
                margin-top: getRem(-3);
              }
            }
        }

        .purchase-history-card {
          position: relative;
          display: flex;
          padding: getRem(16) getRem(16) getRem(16) getRem(24);
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
          color: $contentLeading;
          background: $background;
          box-shadow: $shadowInfoCard;
          border-radius: getRem(8);
          margin-bottom: getRem(20);
          cursor: pointer;

          @include breakpoint(mobile_landscape) {
            margin-bottom: 0;
          }

          &:hover {
            background: $backgroundControls;
          }

          svg {
            color: $secondary;
            width: getRem(24) !important;
            height: getRem(24) !important;
          }
        }
      }
    }
  }

  &-content {
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: getRem(16);
    margin-left: getRem(16);
    margin-right: getRem(16);
    flex-wrap: wrap;
    padding-bottom: getRem(4);
    padding-top: getRem(4);

    @include breakpoint(mobile_landscape) {
      width: getRem(506);
      margin-left: auto;
      margin-right: auto;
    }

    @include breakpoint(tablet_landscape) {
      margin-left: getRem(30);
      margin-right: getRem(20);
      width: auto
    }

    &-product-card {
      display: flex;
      position: relative;
      height: getRem(438);
      padding: getRem(16);
      flex-direction: column;
      align-items: flex-start;
      gap: getRem(16);
      border-radius: getRem(8);
      background: $background;
      box-shadow: $shadowInfoCard;

      @include breakpoint(mobile_landscape) {
        height: getRem(339);
      }

      &-overlay {
        position: absolute;
        left: getRem(16);
        top: getRem(16);
        width: getRem(311) !important;
        height: getRem(311) !important;
        pointer-events: none;
        z-index: 1;

        @include breakpoint(mobile_landscape) {
          width: getRem(213) !important;
          height: getRem(213) !important;
        }

        &-labels {
          position: absolute;
          top: getRem(8);
          right: getRem(6);
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          gap: getRem(4);

          &-item {
            display: flex;
            height: getRem(12);
            border-radius: getRem(3);
            padding: getRem(2) getRem(8);
            color: $onSecondary;
            font-size: getRem(8) !important;
            font-weight: 600 !important;
          }
        }

        &-delivery-time {
          position: absolute;
          bottom: 0;
          left: 0;
          display: inline-flex;
          padding: getRem(4) getRem(10);
          align-items: center;
          gap: getRem(4);
          border-radius: 0 getRem(16) 0 getRem(4);
          background: $primary;
          color: $onDisabled;
          font-size: getRem(10) !important;
          font-weight: 500 !important;

          svg {
            width: getRem(16) !important;
            height: getRem(16) !important;
            color: $onDisabled;
          }
        }
      }

      &-image {
        width: getRem(311) !important;
        height: getRem(311) !important;
        border-radius: getRem(4);
        flex-shrink: 0;
        object-fit: cover;
        position: relative;

        @include breakpoint(mobile_landscape) {
          width: getRem(213) !important;
          height: getRem(213) !important;
        }
      }

      &-info {
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        justify-content: space-between;
        align-items: flex-start;
        align-self: stretch;
        height: getRem(80);

        &-bottom {
          display: flex;
          justify-content: space-between;
          align-items: center;
          align-self: stretch;
        }

        &-name {
          flex-wrap: wrap;
          max-width: getRem(300);
          display: -webkit-box;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-word;
          -webkit-line-clamp: 2;
          max-height: getRem(50);

          @include breakpoint(mobile_landscape) {
            max-width: getRem(213);
          }

          &.available {
            color: $contentLeading;
          }

          &.unavailable {
            color: $disabled;
          }
        }

        &-status {
          &.green {
            color: $contentSuccess;
          }

          &.red {
            color: $contentError;
          }
        }

        &-price {
          overflow: visible;

          &.available {
            color: $secondary;
          }

          &.unavailable {
            color: $disabled;
          }

          svg {
            overflow: visible;
            margin-top: getRem(-3);
            width: getRem(20) !important;
            height: getRem(20) !important;
          }
        }
      }

      &-unpublished {
        position: absolute;
        top: getRem(160);
        left: getRem(100);
        display: flex;
        align-items: center;
        justify-content: center;
        color: $onSecondary;
        font-size: getRem(14);
        font-weight: 600;
        z-index: 1;

        @include breakpoint(mobile_landscape) {
          top: getRem(112);
          left: getRem(75);
        }
      }

      &-hover {
        position: absolute;
        top: getRem(16);
        left: getRem(16);
        width: getRem(311) !important;
        height: getRem(311) !important;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: getRem(4);
        transition: opacity 0.3s ease;

        &.unpublished {
          background: rgba(0, 0, 0, 0.5);
        }

        &.out-of-stock {
          background: rgba(255, 255, 255, 0.3);
        }

        &-text {
          color: $onSecondary;
          font-size: getRem(14);
          font-weight: 600;
          opacity: 0;
        }
      }

      @include breakpoint(mobile_landscape) {
        &-hover {
          width: getRem(213) !important;
          height: getRem(213) !important;
        }
      }

      @include breakpoint(desktop) {
        &:hover {
          cursor: pointer;
          .shop-content-product-card-hover {
            background: rgba(0, 0, 0, 0.3);

            &.unpublished {
              background: rgba(0, 0, 0, 0.7);
            }

            &.out-of-stock {
              background: rgba(255, 255, 255, 0.5);
            }

            &-text {
              opacity: 1;

              &.out-of-stock {
                color: $primary;
              }
            }
          }
        }
      }

    }

    &-empty-result {
      p {
        margin-top: getRem(40) !important;
        line-height: getRem(40) !important;
        position: relative !important;
      }
    }
  }
}