@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.onboarding-requests {

    &-header {
        @extend .table-base-header-type-1;

        padding-bottom: getRem(40);
        margin-bottom: 0;

        @include breakpoint(mobile_landscape) {
            margin-bottom: 0;
        }

        @include breakpoint(tablet_landscape) {
            display: block;
        }

        &-user {
            @extend .onboarding-requests-header;
            padding-bottom: getRem(10);
        }
    }

    &-content {
        display: flex;
        justify-content: center;

        &-table {
            @extend .table-base;

            &-first-column {
                padding-left: getRem(96);
            }

            &-multi-line {
                white-space: pre-line;
                word-break: break-all;
                width: getRem(450);
                padding-top: getRem(10);
                padding-bottom: getRem(10);
                padding-right: getRem(50);

                .cell-limited-text {
                    width: fit-content;
                    max-width: getRem(250);
                    overflow: clip;
                }
            }

            td:last-child {
                width: getRem(170);
            }
        }
    }

    &-popup-content {
        &-label {
            margin-top: getRem(15);
            padding-left: getRem(10);
            color: $contentSupporting;
        }

        &-reason {
            width: getRem(318);
            height: getRem(85);
            margin: auto;
            margin-bottom: getRem(15);

            @include breakpoint(mobile) {
                width: 100%;
            }

            @include breakpoint(desktop) {
                width: getRem(318);
            }
        }
    }
}

.show-panel-approve {
  color: $secondary;
  text-decoration: underline;
  cursor: pointer;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(getRem(2));
}

.approved-onboarding-requests-span {
  color: $contentSuccess;
}

.declined-onboarding-requests-span {
  color: $contentError;
}
