@import '../../../styles/index.scss';


.select-panel {
  display: inline-flex;
  gap: getRem(16);
  max-width: getRem(343);
  overflow-x: scroll;

  @include breakpoint(mobile_landscape) {
    max-width: getRem(500);
    margin-bottom: getRem(-20);
  }

  @include breakpoint(tablet_landscape) {
      max-width: getRem(600);
  }

  @include breakpoint(desktop) {
    max-width: getRem(1000);
  }

  &::-webkit-scrollbar {
    visibility: hidden !important;
  }

  &::-webkit-scrollbar-thumb {
    visibility: hidden !important;
  }

  &::-webkit-scrollbar-track {
    visibility: hidden !important;
  }

  &-span {
    display: flex;
    align-items: center;
    color: $contentAccessory;
    padding: getRem(4);
    width: max-content;
    white-space: nowrap;
  }

  &-button {
    border: none;
    padding: getRem(4);
    cursor: pointer;
    outline: inherit;
    background: none;
    color: $contentSupporting;
    white-space: nowrap;
    width: max-content;
    flex-wrap: nowrap;

    &-selected {
      color: $contentSupporting;
      text-decoration: underline;
      text-decoration-color: $secondary;
    }
  }
}