@import '../../styles/index.scss';


.carousel-filter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: getRem(356);

  &-arrow {
    background: none;
    border: none;
    cursor: pointer;
    color: $contentAccessory;
    display: flex;
    align-items: center;
    justify-content: center;

    &:disabled {
      opacity: 0.3;
      cursor: default;
    }
  }

  &-options {
    display: flex;
    align-items: center;
    gap: getRem(32);
    margin-left: getRem(32);
    margin-right: getRem(32);
  }

  &-option {
    font-size: getRem(18);
    margin: 0 getRem(12);
    cursor: pointer;
    position: relative;

    &.disabled {
        color: $contentAccessory;
        cursor: default;

      &:hover {
        color: $contentAccessory;
      }
    }

    &.selected {
      color: $contentLeading;
      font-weight: bold;

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: getRem(2);
        background-color: $secondary;
      }
    }

    &:hover {
      color: $contentLeading;
    }
  }
}
