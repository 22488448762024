@import "../../styles/index.scss";

.toolkit-text-area {
    min-height: getRem(80);
    width: 100%;
    resize: none;
    outline: none;
    padding: getRem(12);
    border: getRem(0.5) solid rgba(1, 33, 56, 0.5);
    border-radius: getRem(8);
    color: $contentSupporting;

    &-wrapper {
        padding: 0;
    }

    &-label {
        margin: 0;
        padding-left: getRem(10);
        padding-bottom: getRem(4);
        color: $contentSupporting;
    }
}
