@import '../../styles/index.scss';
@import '../../styles/common.scss';


.reports {
    width: getRem(375);
    overflow-x: hidden;
    margin-top: getRem(-30);
    @include breakpoint(mobile_landscape) {
        width: auto;
    }

    &-header {
        padding-right: getRem(25);
        padding-left: getRem(25);
        padding-top: getRem(0);
        margin-top: getRem(24);
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    
        button {
            margin-top: getRem(12);
            @include breakpoint(mobile_landscape) {
                margin-top: 0;
            }
        }

        &-with-search {
            @extend .reports-header;
            flex-direction: column;
            align-items: flex-end;
            height: max-content;
            margin-top: getRem(50);
            gap: getRem(8);
            
            @include breakpoint(mobile_landscape) {
                flex-direction: row;
                align-items: baseline;
            }
        }
    }

    &-bottom {
        display: flex;
        margin-bottom: getRem(20);
        flex-direction: column-reverse;
        max-width: getRem(1320);
        margin-left: auto;
        margin-right: auto;
        @include breakpoint(mobile_landscape) {
            align-items: flex-start;
        }
        @include breakpoint(tablet_landscape) {
            flex-direction: row;
        }
    }

    &-search {
        width: getRem(330);
        @include breakpoint(tablet_landscape) {
            width: getRem(464);
        }
    }

    &-users {
        position: absolute;
        background: $surface;
        z-index: 5;
        padding-top: getRem(12);
        padding-bottom: getRem(12);
        width: getRem(289);
        box-shadow: $shadowInfoCard;
        border-radius: getRem(8);
        margin-left: getRem(47);
        margin-top: getRem(35);
        max-height: getRem(500);
        overflow-y: auto;

        &::-webkit-scrollbar {
            @extend .custom-scroll-webkit-scrollbar;
        }

        &::-webkit-scrollbar-track {
            @extend .custom-scroll-webkit-scrollbar-track;
        }

        &::-webkit-scrollbar-thumb {
            @extend .custom-scroll-webkit-scrollbar-thumb;
        }

        &-item {
            cursor: pointer;
            display: flex;
            align-items: center;
            text-transform: capitalize;
            height: getRem(32);

            &:hover {
                background-color: $backgroundControls;
            }
            img {
                margin-left: getRem(16);
                margin-right: getRem(8);
                width: getRem(24);
                height: getRem(24);
                border-radius: getRem(4);
            }
        }
    }


    .scrollable-div {
        overflow-x: scroll;
        white-space: nowrap;
        padding-left: getRem(16);
        padding-right: getRem(16);
        
        @include breakpoint(mobile_landscape) {
            padding-left: getRem(16);
            padding-right: getRem(16);
        }

        @include breakpoint(desktop) {
            padding-left: getRem(0);
            padding-right: getRem(0);
        }

        @include breakpoint(desktop) {
            overflow-x: hidden;
            white-space:initial;
            flex-direction: row;
            justify-content: center;
        }

        &::-webkit-scrollbar {
            display: none;
        }
    }

    // styles for Chart component
    &-chart {
        display: inline-block;
        width: max-content;
        background-color: $surface;
        box-shadow: $shadowScheduleReports;
        border-radius: getRem(8);
        margin-bottom: getRem(32);
        padding-bottom: getRem(24);
        margin-top: getRem(24);

        @include breakpoint(desktop) {
            margin: 0 auto;
            margin-top: getRem(32);
            margin-bottom: getRem(24);
            display: block;
            max-width: getRem(1320);
            width: auto

        }

        &-block {
            margin-top: getRem(5);
            height: getRem(248);
            background-color: $backgroundControls;
            width: 100%;
            position: relative;
        }

        &-empty {
            display: block;
            position: absolute;
            margin: auto;
            width: 100%;
            color: $contentAccessory;
            text-align: center;
            margin-top: getRem(-160);
            height: getRem(160);
        }

        &-progress {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }


    &-menu {
        padding: getRem(20) getRem(28) 0;

        &-active {
            padding-bottom: getRem(3);
            border-bottom: getRem(1) solid $contentActionPrimary; 
        }
        
        &-item {
            cursor: pointer;
            margin-right: getRem(28);

            &-period {
                color: $contentAccessory !important;
            }
        }

        &-all-records {
            display: inline-block;
            min-width: getRem(100);
            color: $contentActionPrimary;
            text-decoration: underline;
            padding-top: getRem(20);

            span {
                cursor: pointer;
            }
        }

        &-project {
            margin-left: getRem(0);
            display: flex;
            flex-direction: row;
            justify-content: end;
            align-items: flex-start;

            .info-message svg{
                margin-top: getRem(-9);
            }

            @include breakpoint(desktop) {
                margin-left: getRem(80);
            }

            &-text {
                cursor: default;
                margin-right: getRem(16);
                color: $contentAccessory !important;
            }

            .project-dropdown {
                margin-left: getRem(15);
            }
        }
    }

    .scrollable-table {
        width: 100%;
        overflow-x: scroll;
        white-space: nowrap;
        padding-left: getRem(16);
        padding-right: getRem(16);

        &::-webkit-scrollbar {
            display: none;
        }

        @include breakpoint(mobile_landscape) {
            overflow-x: hidden;
            white-space:initial;
        }

        @include breakpoint(desktop) {
            padding-left: getRem(26);
        padding-right: getRem(26);
        }
    }
    // styles for Table component
    &-table {
        display: inline-block;
        background: $surface;
        box-shadow: $shadowTable;
        border-radius: getRem(8);
        width: getRem(550);
        margin-top: getRem(16);
        margin-bottom: getRem(16);
        min-height: getRem(300);

        @include breakpoint(mobile_landscape) {
            width: -webkit-fill-available;
            min-width: getRem(350);
            margin-right: getRem(16);
            min-height: getRem(328);
        }
    
        @include breakpoint(tablet) {
            width: getRem(646);
        }

        @include breakpoint(tablet_landscape) {
            margin-right: 0;
            margin-top: 0;
            min-width: getRem(350);
            width: getRem(530);
        }

        @include breakpoint(desktop) {
            width: getRem(699);
        }

        > div:first-child {
            display: flex;
        }

        table {
            padding: getRem(22) getRem(16) getRem(32);
            border-spacing: 0 getRem(16);
            border-collapse: separate;
            width: 100%;

            th {
                text-align: left;

                &:first-child {
                    padding-left: getRem(15);
                    text-align: start;
                    width: 100%;

                    @include breakpoint(mobile_landscape) {
                        padding-left: getRem(20);
                        width: getRem(550);
                    }
                }

                &:nth-child(2) {
                    min-width: getRem(140);
                
                    @include breakpoint(mobile_landscape) {
                        min-width: getRem(140);
                    }
                }

                &:nth-child(3) {
                    min-width: getRem(110);

                    @include breakpoint(mobile_landscape) {
                        min-width: getRem(102);
                    }
                }
            }
        }

        .reports-menu {
            width: 100%;
        }

        &-item {
            width: getRem(800);
            height: getRem(56);
            border-radius: getRem(8);

            @include breakpoint(mobile_landscape) {
                box-shadow: $shadowTableRow;
            }

            td {
                text-align: left;
                @extend .table-box-shadow-styles-td;

                li {
                    &::marker {
                        display: block;
                        font-size: getRem(20);
                    }

                    span {
                        position: relative;
                        left: getRem(-10);
                        bottom: getRem(1);
                    }
                }
            }
        
            td:first-child {
                @extend .table-box-shadow-styles-td-first-child;
                padding-left: getRem(15);
                text-align: start;
                width: 100%;

                @include breakpoint(mobile_landscape) {
                    padding-left: getRem(20);
                }
            }

            td:nth-child(2) {
                min-width: getRem(140);

                @include breakpoint(mobile_landscape) {
                    min-width: getRem(140);
                }
            }

            td:nth-child(3) {
                min-width: getRem(110);

                @include breakpoint(mobile_landscape) {
                    min-width: getRem(102);
                }
            }

            td:last-child {
                @extend .table-box-shadow-styles-td-last-child;
            }
        }

        &-empty {
            color: $contentAccessory;
            position: inherit;
            text-align: center;
            padding-top: getRem(10);
        }
    }

    // styles for InfoCards component
    &-info {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        gap: getRem(24);
        margin-bottom: getRem(39);
        width: 100%;

        @include breakpoint(tablet_landscape) {
            justify-content: space-between;
            margin-left: getRem(24);
        }

        @include breakpoint(desktop) {
            justify-content: flex-start;
            margin-left: getRem(24);
        }

        &-card, &-card-big {
            background-color: $surface;
            box-shadow: $shadowInfoCard;
            border-radius: getRem(8);
            height: getRem(136);
            padding-top: getRem(24);
            padding-left: getRem(24);
        }

        &-card-big {
            position: relative;
            width: getRem(343);

            @include breakpoint(tablet) {
                width: getRem(286);
            }

            &-expand-icon{
                width: getRem(18);
                height: getRem(18);
                position: absolute;
                right: getRem(15);
                color: $switcherCircleOn;
            }
        }

        &-card {
            width: getRem(286);
            @include breakpoint(desktop) {
                width: getRem(154);
            }

            &-header {
                padding-bottom: getRem(3);
            }

            &-label {
                padding-left: getRem(8);
                padding-top: getRem(3);
                color: $contentAccessory;
            }

            &-bottom {
                display: flex;
                gap: getRem(32);
                padding-top: getRem(5);

                &-hours {
                    color: $contentHighlightPrimary;
                }

                &-rate {
                    color: $contentHighlightPrimary;
                    margin-left: getRem(15)
                }

                &-salary-type {
                    position: relative;
                    bottom: getRem(0);
                    display: inline-block;
                    height: 100%;
                    padding-left: getRem(2);
                    color: $contentHighlightPrimary;
                }

                &-coefficient {
                    position: relative;
                    margin-left: getRem(1);
                    top: getRem(-4);
                    display: inline-block;
                    color: $contentHighlightPrimary;
                }
            }

            &-box {
                display: block;
                max-height: getRem(75);
                overflow-y: auto;

                &::-webkit-scrollbar {
                    @extend .custom-scroll-webkit-scrollbar;
                }
                  
                &::-webkit-scrollbar-track {
                    @extend .custom-scroll-webkit-scrollbar-track;
                }
                  
                &::-webkit-scrollbar-thumb {
                    @extend .custom-scroll-webkit-scrollbar-thumb;
                }
            }
        }
        &-main-text {
            color: $contentHighlightPrimary;
            margin-bottom: getRem(6);
        }

        &-secondary-text {
            color: $contentAccessory;
            margin-top: getRem(2);
        }

        button {
            width: getRem(154);
            height: getRem(40);
            background-color: $contentActionPrimary;
            color: $onSecondary;
            border-radius: getRem(8);
            border: 0;
            @include breakpoint(mobile_landscape) {
                width: getRem(286);
            }
            @include breakpoint(tablet_landscape) {
                width: getRem(154);
            }
        }
    }

    &-user-not-selected {
        margin-top: getRem(10);
        text-align: center;
        p {
            color: $contentAccessory;
            line-height: getRem(200);
            margin-top: getRem(-100);
            position: absolute;
            top: 50%;
            width: 100vw;

            @include breakpoint(tablet) {
               width: calc(100vw - getRem(70))
            }
        }
    }
}

.reports-chart {
    .range-date-input-wrapper {
        margin: 0;
        position: relative;
        top: getRem(-1);
    }

    .reports-menu {
        display: flex;
        height: getRem(50);
        gap: getRem(24);
        padding-left: getRem(25);
        padding-right: getRem(19);

        .reports-menu-item {
            display: block;
            width: max-content;
            margin: 0;
            height: min-content;
        }

        .reports-menu-range {
            margin-left: auto;
            float: right;
            text-align: right;
            cursor: default;

            &-date {
                display: block;
                margin-bottom: getRem(2);
                color: $contentSupporting;
            }

            &-days {
                display: block;
                color: $contentAccessory;
            }
        }
    }
}

.reports-chart-block.empty-chart {
    background-color: $surface;
}

.reports-chart-block.loading-chart {
    background-color: $surfaceDivider;
}


.reports-info-popup {
    padding-bottom: getRem(39);

    .custom-popup-card {
        &-title {
            margin-bottom: getRem(32);
        }

        &-content {
            padding-left: getRem(48);
            padding-right: getRem(48);
        }
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: getRem(23);
    }

    &-box {
        display: flex;
        flex-direction: column;
        gap: getRem(3);
    }
}

.earn-info-popup {
    @extend .reports-info-popup;

    &-wrapper {
        @extend .reports-info-popup-wrapper;
    }

    &-box {
        @extend .reports-info-popup-box;

        > span {
            display: inline-block;

            &:first-child {
                margin-bottom: getRem(5);
                color: $contentSupporting;
            }

            &:not(:first-child) {
                color: $contentHighlightPrimary;
            }
        }
    }
}

.bonus-info-popup {
    @extend .reports-info-popup;

    &-wrapper {
        @extend .reports-info-popup-wrapper;
    }

    &-box {
        @extend .reports-info-popup-box;
        gap: getRem(7);

        > span {
            display: inline-block;

            &:first-child {
                margin-bottom: getRem(1);
                color: $contentSupporting;
            }

            &:not(:first-child) {
                color: $contentHighlightPrimary;
            }
        }
    }

    &-one-time {
        display: flex;
        align-items: center;
        gap: getRem(56);

        span {
            &:first-child {
                color: $contentHighlightPrimary;
            }

            &:last-child {
                color: $contentAccessory;
            }
        }
    }
}

.overtime-info-popup {
    @extend .reports-info-popup;
    
    &-wrapper {
        @extend .reports-info-popup-wrapper;
    }

    &-box {
        @extend .reports-info-popup-box;

        > span {
            &:first-child {
                margin-bottom: getRem(5);
                color: $contentSupporting;
            }
        }
    }

    &-detail {
        display: flex;
        align-items: center;
        width: 100%;
        
        &-earn {
            display: block;
            min-width: 40%;
            color: $contentHighlightPrimary;
        }

        &-hours {
            display: block;
            min-width: 20%;
            color: $contentSupporting;
        }

        &-rate {
            display: block;
            min-width: 60%;
        }

        &-number {
            color: $contentSupporting;
        }

        &-salary-type {
            @extend .reports-info-card-bottom-salary-type;
            color: $contentSupporting;
            padding-top: getRem(0) !important;
        }

        &-coefficient {
            @extend .reports-info-card-bottom-coefficient;
            color: $contentSupporting;
        }
    }
}

.day-time-record-popup {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: getRem(40);
    background-color: $background;
    border-radius: getRem(8);
    overflow-y: auto;
    max-height: getRem(560) !important;

    &-title {
        color: $contentLeading;
        text-align: center;
    }

    &-button {
        display: flex;
        height: getRem(33);
        padding: getRem(8) getRem(32);
        justify-content: center;
        align-items: center;
        gap: getRem(10);
        background-color: $secondary;
        color: $onSecondary;
        text-align: center;
        border: none;
        border-radius: getRem(8);

        &:focus {
            outline: none;
        }

        &:hover {
            cursor: pointer;
            background-color: $secondaryVariant;
        }

        &-close {
            position: absolute;
            top: getRem(23.5);
            right: getRem(24);
            width: getRem(32);
            height: getRem(32);
            color: $disabled;

            &:hover {
                color: $secondary;
                cursor: pointer;
            }
        }
    }

    &-table {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: getRem(32);

        &-row {
            color: $contentSupporting;
            display: flex;
            align-items: flex-start;
            gap: getRem(32);

            &-hours-type {
                width: getRem(145) !important;

                .dot {
                    height: getRem(10);
                    width: getRem(10);
                    border-radius: 50%;
                    display: inline-block;
                    margin-right: getRem(8);
                }
            }

            &-project {
                width: getRem(215) !important;
            }

            &-duration {
                width: getRem(100) !important;
                text-align: right;
            }
        }

        &-divider {
            margin-top: getRem(12);
            margin-bottom: getRem(12);
            color: $contentSupporting;
            opacity: 0.2;
        }
    }

    .heading-color {
        color: $contentLeading !important;
    }
}
