@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.ns-table-container {
  display: flex;
}

.ns-table-accordion {
  overflow: auto;
  background-color: $background;
  box-shadow: 0 0.1rem 0.2rem $primary10;
  height: min-content;
  border-radius: 0 0 0.5rem 0.5rem !important;
  margin-top: 0;
  transform: translateY(-0.5rem);

  &-cell {
    border-radius: 0 0 0.5rem 0.5rem !important;
  }
}

.ns-table {
  @extend .table-base;

  &-small {
    border-spacing: 0 0.5rem;
  }

  table-layout: fixed;

  &-first-column {
    padding-left: getRem(36);
  }

  &-row {
    &-small {
      height: 2.5rem !important; // It's here until we get rid of custom-scrollable-table
    }

    &-selectable {
      &:hover {
        cursor: pointer;
      }
    }

    &-selected {
      box-shadow: 0 -1px 0.2rem $primary10 !important;
      background: $backgroundSelected !important;
      border-radius: 0.5rem 0.5rem 0 0 !important;

      td {
        border-bottom-left-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
      }
    }

    td:last-child {
      width: getRem(150) !important;
    }
  }

  &-column {
    &-date {
      width: 6rem !important;
    }

    &-expand-action {
      width: getRem(10) !important;
    }
  }

  &-body {
    &-expand {
      width: getRem(10) !important;

      button {
        float: right;
      }

      svg {
        color: $onPrimary;
      }
    }
  }

  &-cell {
    width: 95%;

    &-content {
      overflow-x: hidden;
      overflow-y: visible;
      text-overflow: ellipsis;
    }
  }

  th:first-child {
    padding-left: getRem(36);
  }

  td {
    font-family: "Gilroy", serif !important;
    color: $blackTransparent !important;
    padding-right: getRem(12);
    min-width: getRem(61);
    overflow-x: hidden;
  }

  th {
    padding-right: getRem(12);
    white-space: normal;
    width: getRem(100);
    overflow-x: hidden;
    text-overflow: ellipsis;
  }

}


