@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.purchase-requests {
  display: block;

  &-header {
    @extend .table-base-header-type-1;

    &-button {
      @extend .table-base-header-type-1-button;
    }

    &-search {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: getRem(24);
      height: fit-content;
      width: 100%;

      @include breakpoint(mobile_landscape) {
        margin-bottom: getRem(0);
      }
    }
  }

  &-content {
    display: flex;
    justify-content: left;

    &-table {
      @extend .table-base;
      margin-top: getRem(-20);

      th {
        padding-right: getRem(16);
      }

      td {
        padding-right: getRem(16);
        min-width: getRem(61);
        color: $contentSupporting;
      }

      &-header {
        &-employee {
          min-width: getRem(225) !important;
          width: getRem(225) !important;
        }

        &-description {
          white-space: normal;
          word-break: break-word;
          min-width: 10rem !important;
          width: 13rem !important;
        }

        &-status {
          width: 12rem !important;
        }

        &-amount {
          width: 6rem !important;
        }

        &-actions {
          width: getRem(160) !important;
        }
      }


      &-body {
        &-employee {
          min-width: getRem(225) !important;
          color: $contentSupporting;
          position: relative;

          @include breakpoint(mobile_landscape) {
            width: getRem(265) !important;
          }

          > div {
            display: flex;

            > span {
              display: inline-flex;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              top: getRem(8);
            }

            > img {
              width: getRem(48);
              height: getRem(48);
              flex-shrink: 0;
              border-radius: getRem(4);
              margin-right: getRem(16);
              margin-top: getRem(4);
              margin-bottom: getRem(4);
            }
          }

        }

        &-description {
          max-width: getRem(174);
          height: max-content;
          white-space: normal;
          word-break: break-word;
          overflow: hidden;

          div {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            max-width: getRem(150) !important;
            text-overflow: ellipsis;
            white-space: normal;
            word-break: break-word;
            max-height: getRem(50);

            @include breakpoint(mobile_landscape) {
              max-width: getRem(250) !important;
            }
          }
        }

        &-price {
          width: getRem(88);
          min-width: getRem(61) !important;
          overflow: visible;

          svg {
            width: getRem(18);
            height: getRem(18);
            margin-top: getRem(-3);
            margin-left: getRem(2);
          }
        }

        &-reason {
          width: getRem(159);

          > span {
            overflow: hidden;

            > span {
              max-width: getRem(150) !important;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }

        &-actions {
          text-overflow: clip !important;
        }

      }

      &-amount {
        width: getRem(61);
        min-width: getRem(61) !important;
      }

      &-description {
        max-width: getRem(174);
        height: max-content;
        white-space: normal;
        word-break: break-word;

        div {
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          overflow: hidden;
          width: max-content !important;
          max-width: getRem(150) !important;
          text-overflow: ellipsis;
          white-space: normal;
          word-break: break-word;
          max-height: getRem(50);

          @include breakpoint(mobile_landscape) {
            max-width: getRem(250) !important;
          }
        }
      }

      &-status {
        width: getRem(156);
      }


      &-last-column {
        width: getRem(116);
      }

      &-green-span {
        color: $contentSuccess;
      }

      &-red-span {
        color: $contentError;
      }
    }
  }
}