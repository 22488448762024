@import '../../styles/index.scss';


.ns-divider {
  border: none;

  &-horizontal {
    border-top: 2px solid $surfaceDivider;
    margin: 0.8rem 0;
  }

  &-vertical {
    border-left: 2px solid $surfaceDivider;
    margin: 0 0.8rem;
  }
}
