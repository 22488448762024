@import '../../styles/index.scss';
@import '../../toolkits/CustomScrollableTable/index.scss';


.time-records-requests {
  &-table {
    &-header {
      &-employee {
        width: getRem(300) !important;
      }

      &-actions {
        width: getRem(160) !important;
      }
    }

    &-body {

      &-employee {
        display: block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;

        span {
          margin-left: getRem(0);
        }
      }

      &-actions {
        text-overflow: revert;

        button {
          border: none;
          background: transparent;

          &:disabled {
            color: $disabled;
            cursor: text;
          }
        }
      }

    }
  }
}
