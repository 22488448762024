@import '../../styles/index.scss';
@import '../../styles/common.scss';

.table-base {
    min-width: getRem(640);
    width: 100%;
    border-collapse: separate;
    padding-left: getRem(16);
    padding-right: getRem(16);
    border-spacing: 0 getRem(16);

    @include breakpoint(mobile_landscape) {
        padding-left: getRem(25);
        padding-right: getRem(25);
    }

    // search & button
    &-header-type-1 {
        display: block;
        height: fit-content;
        width: 100%;
        padding-left: getRem(16);
        padding-right: getRem(16);
        padding-top: getRem(24);
        margin-bottom: getRem(20);

        @include breakpoint(mobile_landscape) {
            margin-bottom: getRem(30);
            padding-left: getRem(25);
            padding-right: getRem(25);
        }

        @include breakpoint(tablet_landscape) {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: center;
        }

        &-button {
            display: flex;
            justify-content: right;
            padding-top: getRem(24);

            @include breakpoint(tablet_landscape) {
                padding-top: 0;
            }
        }
    }
}


.custom-scrollable-table {
    width: 100%;
    display: flex;
    align-items: center;
    overflow-x: auto;
    white-space: nowrap;

    border-spacing: 0;
    border-collapse: separate;

    padding-bottom: 2rem;

    @include breakpoint(tablet_landscape){
        overflow-x: hidden;
    }

    &-span {
        @extend .t-b3;
        color: $contentLeading;
    }

    &-head-span {
        @extend .t-h3;
        color: $contentLeading;
        text-transform: uppercase;
    }

    &-head-span-first {
        @extend .t-h3;
        color: $contentLeading;
        padding-left: getRem(31);
    }

    &-link {
        color: $contentActionPrimary;
        margin-right: getRem(25);
        text-decoration: underline;
        text-transform: lowercase;
        cursor: pointer;
    }

    &-employee {
        display: flex;
        align-items: center;
        &-image {
            margin-left: getRem(31);
            
            img {
                height: getRem(48);
                width: getRem(48);
                margin-right: getRem(16);
                border-radius: getRem(4);
            }
        }
    
        &-name {
            @extend .t-s1;
            color: $contentLeading;
        }
    }

    &-row {
        background-color: $surface;
        height: getRem(56);
        transition: all 0.2s;
        border-radius: getRem(8);

        &-user {
            @extend .custom-scrollable-table-row;
            height: getRem(72);        
        }

        @include breakpoint(mobile_landscape) {
            box-shadow: $shadowTableRow;
        }

        td {
            @extend .table-box-shadow-styles-td;
            @extend .t-b2;
            color: $contentSupporting;
        }

        td:nth-child(n+1){
            min-width: getRem(150);
        }

        td:first-child {
            @extend .table-box-shadow-styles-td-first-child;
        }
    
        td:last-child {
            @extend .table-box-shadow-styles-td-last-child;
        }
    }

    &-row:hover {
        background-color: $backgroundControls;
    }
}

/*.custom-scrollable-table::-webkit-scrollbar-track {
    box-shadow: $shadowInfoCard;
    background-color: $background;
    border-radius: getRem(10);
}

.custom-scrollable-table::-webkit-scrollbar {
    width: getRem(10);
    border-radius: getRem(10);
    background-color: $background;
}

.custom-scrollable-table::-webkit-scrollbar-thumb {
    background-color: $secondaryVariant;
    border-radius: getRem(10);
    background-image: -webkit-linear-gradient(45deg,
    rgba(255, 255, 255, .2) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, .2) 50%,
    rgba(255, 255, 255, .2) 75%,
    transparent 75%,
    transparent)
}*/